import * as Sentry from '@sentry/nextjs';

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const sleepRandom = (min: number, max: number) => {
  return new Promise((resolve) =>
    setTimeout(resolve, Math.floor(Math.random() * (max - min + 1)) + min)
  );
};

export const convertSecondsToMMSS = (seconds: number) => {
  const minutes = Math.floor(seconds / 60); // Get total minutes
  const secs = seconds % 60; // Get remaining seconds
  const paddedSeconds = secs.toString().padStart(2, '0'); // Pad single digit seconds with a zero
  return `${minutes}:${paddedSeconds}`; // Return formatted string
};

export const logClientException = (exception: any, context = {}) => {
  console.log('NODE_ENV', process.env.NODE_ENV);
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(exception, context);
  } else {
    console.error(exception);
  }
};

export const removeTextBeforeFirstCharacter = (
  inputText: string,
  character: string
) => {
  const firstBracketIndex = inputText.indexOf(character);

  if (firstBracketIndex !== -1) {
    return inputText.substring(firstBracketIndex);
  } else {
    return inputText;
  }
};

export const getSportRadarTeamIdWithoutPrefix = (idWithPrefix: string) => {
  return +idWithPrefix.replace('sr:competitor:', '');
};

type ColorEnum =
  | 'reset'
  | 'black'
  | 'red'
  | 'green'
  | 'yellow'
  | 'blue'
  | 'magenta'
  | 'cyan'
  | 'white';
export const lg = (message: string, color: ColorEnum = 'reset') => {
  const colorCodes = {
    reset: '\x1b[0m',
    black: '\x1b[30m',
    red: '\x1b[31m',
    green: '\x1b[32m',
    yellow: '\x1b[33m',
    blue: '\x1b[34m',
    magenta: '\x1b[35m',
    cyan: '\x1b[36m',
    white: '\x1b[37m',
  };

  // Get the color code
  const colorCode = colorCodes[color] || colorCodes.reset;
  const logMessage = `${colorCode}${message}${colorCodes.white}`;
  console.log(logMessage);
};

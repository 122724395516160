'use client';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { logClientException } from '@/utils/utils';

export default function Error({ error }: any) {
  useEffect(() => {
    // Log the error to Sentry
    logClientException(error);
  }, [error]);

  console.log(error);
  return (
    <div className="flex flex-col justify-center items-center my-20 h-screen">
      <p className="font-bold text-2xl text-foreground">
        Huch, da ist etwas schief gelaufen...
      </p>
      <Link href="/" className="mt-10">
        <Button variant="default" size="sm">
          Go back home
        </Button>
      </Link>
    </div>
  );
}
